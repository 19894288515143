import { useState, useEffect, useRef } from 'react';
import { FunnelIcon, XMarkIcon, } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

const CallsPage = () => {
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({});
  const [showPopover, setShowPopover] = useState(false);
  const [selectedCall, setSelectedCall] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const filterRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    setUserRoles(JSON.parse(localStorage.getItem('user'))?.rules || []);

    const fetchCalls = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found in local storage');
        }
        const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/callsCalendar?callStatus=Confirmed&season=2024 / 25`, {
          headers: {
            'authorization': token
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch calls');
        }
        const data = await response.json();
        setCalls(data.value);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCalls();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setShowFilters(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterRef]);

  const handleItemClick = (call) => {
    setSelectedCall(call);
    setShowPopover(true);
  };

  const handleOptionSelect = (option) => {
    if (selectedCall) {
      switch (option) {
        case 'Shorex':
          navigate(`/shorex/${selectedCall.fields.FID}`);
          break;
        case 'Port & Terminal':
          navigate(`/form/${selectedCall.fields.id}`);
          break;
      }
    }
    setShowPopover(false);
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen"><div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 dark:border-blue-300"></div></div>;
  }

  if (error) {
    return <div className="text-center text-red-500 dark:text-red-400">{error}</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleFilterChange = (column, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [column]: value
    }));
  };

  const handleDateChange = (column, value) => {
    // Convert the date to ISO format for consistency
    const isoDate = value ? new Date(value).toISOString().split('T')[0] : '';
    setFilters(prevFilters => ({
      ...prevFilters,
      [column]: isoDate
    }));
  };

  const clearFilters = () => {
    setFilters({
      ETA: '',
      ETD: '',
      'Ship Name': '',
      'Cruise Line': '',
      'Port of call': '',
      'Call Type': '',
      'Call Status': ''
    });
  };

  const filteredCalls = calls
    .filter(call => {
      return Object.entries(filters).every(([column, filterValue]) => {
        if (!filterValue) return true;
        const cellValue = call.fields[column.split(' ').join('')];
        return cellValue && cellValue.toLowerCase().includes(filterValue.toLowerCase());
      });
    })
    .sort((a, b) => {
      // Convert ETA strings to Date objects for comparison
      const dateA = new Date(a.fields.ETA || '');
      const dateB = new Date(b.fields.ETA || '');
      return dateA - dateB;
    });

  const findNearestETA = (calls) => {
    const today = new Date();
    return calls.reduce((nearest, current) => {
      if (!current.fields.ETA) return nearest;
      
      const currentDate = new Date(current.fields.ETA);
      if (currentDate < today) return nearest;
      
      if (!nearest) return current;
      
      const nearestDate = new Date(nearest.fields.ETA);
      const currentDiff = Math.abs(currentDate - today);
      const nearestDiff = Math.abs(nearestDate - today);
      
      return currentDiff < nearestDiff ? current : nearest;
    }, null);
  };

  return (
    <div className="container mx-auto p-4 relative">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold dark:text-white">Calls Calendar</h1>
        <div className="flex items-center">
          <button
            onClick={() => setShowFilters(!showFilters)}
            type="button"
            className="flex items-center px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-300 mr-2"
          >
            <FunnelIcon className="h-5 w-5 mr-2" />
            Filters
          </button>
        </div>
      </div>
      {showFilters && (
        <div ref={filterRef} className="absolute z-10 right-0 mt-2 w-64 text-sm text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
          <div className="flex justify-between px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
            <h3 className="font-semibold text-gray-900 dark:text-white">Filters</h3>
            <button onClick={() => setFilters({})} className="text-xs text-red-500 hover:text-red-600 dark:text-red-400 dark:hover:text-red-300">Clear All</button>
          </div>
          <div className="px-3 py-2">
            {['ETA', 'ETD', 'Ship Name', 'Cruise Line', 'Port of call', 'Call Type', 'Call Status'].map(column => (
              <div key={column} className="mb-2">
                <label htmlFor={column} className="block text-sm font-medium text-gray-700 dark:text-gray-300">{column}</label>
                {column === 'ETA' || column === 'ETD' ? (
                  <input
                    type="date"
                    id={column}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    value={filters[column] || ''}
                    onChange={(e) => setFilters(prevFilters => ({
                      ...prevFilters,
                      [column]: e.target.value ? new Date(e.target.value).toISOString().split('T')[0] : ''
                    }))}
                  />
                ) : (
                  <select
                    id={column}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    value={filters[column] || ''}
                    onChange={(e) => setFilters(prevFilters => ({
                      ...prevFilters,
                      [column]: e.target.value
                    }))}
                  >
                    <option value="">All</option>
                    {Array.from(new Set(
                      column === 'Ship Name' ? calls.map(call => call.fields.ShipName) :
                        column === 'Cruise Line' ? calls.map(call => call.fields.CruiseLine) :
                          column === 'Port of call' ? calls.map(call => call.fields.Portofcall) :
                            column === 'Call Type' ? calls.map(call => call.fields.CallType) :
                              column === 'Call Status' ? calls.map(call => call.fields.CallStatus) :
                                calls.map(call => call.fields[column])
                    )).map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </select>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="overflow-x-auto overflow-y-auto" style={{ maxHeight: '92vh' }}>
        <table className="min-w-full bg-white dark:bg-gray-800">
          <thead className="bg-gray-100 dark:bg-gray-700">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Title</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">ETA</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">ETD</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Days in port</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Ship Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Cruise Line</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Port of Call</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Call Type</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Call Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Previous Port</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Next Port</th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
            {filteredCalls.map((call) => {
              const isNearest = findNearestETA(filteredCalls)?.fields.id === call.fields.id;
              return (
                <tr 
                  key={call.fields.id} 
                  className={`cursor-pointer transition-colors duration-200
                    ${isNearest 
                      ? 'bg-blue-200 dark:bg-blue-900/30 hover:bg-blue-100 dark:hover:bg-blue-900/50' 
                      : 'hover:bg-gray-100 dark:hover:bg-gray-700'
                    }`}
                  onClick={() => handleItemClick(call)}
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">{call.fields.Title || 'N/A'}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">{call.fields.ETA ? formatDate(call.fields.ETA) : 'N/A'}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">{call.fields.ETD ? formatDate(call.fields.ETD) : 'N/A'}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">{call.fields.Daysinport || 'N/A'}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">{call.fields.ShipName || 'N/A'}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">{call.fields.CruiseLine || 'N/A'}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">
                      {call.fields.Portofcall || 'N/A'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200">
                      {call.fields.CallType || 'N/A'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200">
                      {call.fields.CallStatus || 'N/A'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">{call.fields.PreviousPort || 'N/A'}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">{call.fields.NextPort || 'N/A'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {showPopover && selectedCall && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white dark:bg-gray-800 p-6 rounded shadow-lg">
            <h3 className="text-lg font-bold mb-4">Select an Option for {selectedCall.fields.Title}</h3>
            <button
              className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-2 w-full ${(!userRoles.includes('Admin') && !userRoles.includes('SHOREX')) ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={() => handleOptionSelect('Shorex')}
              disabled={!userRoles.includes('Admin') && !userRoles.includes('SHOREX')}
            >
              Shorex
            </button>
            <button
              className={`bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full ${(!userRoles.includes('Admin') && !userRoles.includes('PAD') && !userRoles.includes('GAC')) ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={() => handleOptionSelect('Port & Terminal')}
              disabled={!userRoles.includes('Admin') && !userRoles.includes('PAD') && !userRoles.includes('GAC')}
            >
              Port & Terminal
            </button>
            <button
              className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => setShowPopover(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CallsPage;
